export const routePath = {
    login: 'login',
    landing: '',
    mainTransaction: 'main-transaction',
    billPayment: 'bill-payment',
    coPay: 'co-pay',
    subCoPay: 'sub-co-pay',
    uam: 'user-access-management',
    billerProfileMgmt: 'biller-profile-management',
    billerMaintain: 'biller-maintenance-configuration',
    maintainIconUrl: 'maintain-icon-and-url',
    billerFee: 'fee-configuration-inquiry',
    maintainDirectlink: 'maintain-directlink-configuration',
    maintainPartnerPayment: 'maintain-partner-payment',
    any: '**',
}