import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http"
import { inject } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { catchError } from "rxjs"
import { dataMustBeNumericFormat, dataNotFound, dateMustBeGreaterThanOrEqualCurrentDate, duplicatedData, endDateMustBeGreaterThanOrEqualStartDate, fileTypeMustBePng, loggedInTwice, missingAuthorizationHeader, notFoundBiller, notifyLoggedInTwice, sessionIsExpired, userDoesNotExistOrRoleIsChanged } from "../constants/display-info-mapping"
import { routePath } from "../constants/route-path"
import { storageKey } from "../constants/storage-key"
import { InfoDialogComponent } from "../dialogs/info-dialog/info-dialog.component"
import { WarnDialogComponent } from "../dialogs/warn-dialog/warn-dialog.component"
import { DisplayInfo } from "../models/display-info"
import { RefreshStorageService } from "../services/refresh-storage.service"

export const httpResponseInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const dialog = inject(MatDialog)
  const router = inject(Router)
  const storageService = inject(RefreshStorageService)

  const showInfoDialog = (data: DisplayInfo): MatDialogRef<InfoDialogComponent, void> => {
    return dialog.open<InfoDialogComponent, DisplayInfo, void>(InfoDialogComponent, {
      data,
      panelClass: 'base-dialog',
      disableClose: true,
    })
  }

  const showErrorDialog = (data: DisplayInfo = { title: 'Attention', description: '' }): MatDialogRef<WarnDialogComponent, void> => {
    return dialog.open<WarnDialogComponent, DisplayInfo, void>(WarnDialogComponent, {
      data,
      panelClass: 'base-dialog',
      disableClose: true,
    })
  }

  return next(req).pipe(
    catchError((res: HttpErrorResponse) => {
      if (isGoogleAuthUrl(req.url)) throw res

      if (!res.error
        || !res.error.status) {
        showErrorDialog({
          title: 'Attention',
          description: `Something went wrong.`,
        })
        throw res
      }

      if (isExpiredSession(res)) {
        showInfoDialog(sessionIsExpired).afterClosed().subscribe(() => {
          storageService.delete(storageKey.auth)
          router.navigate([`/${routePath.login}`])
        })
      } else if (isLoggedInTwice(res)) {
        showInfoDialog(notifyLoggedInTwice).afterClosed().subscribe(() => {
          storageService.delete(storageKey.auth)
          router.navigate([`/${routePath.login}`])
        })
      } else if (doesNotExistUserOrRoleIsChanged(res)) {
        showInfoDialog(userDoesNotExistOrRoleIsChanged).afterClosed().subscribe(() => {
          storageService.delete(storageKey.auth)
          router.navigate([`/${routePath.login}`])
        })
      } else if (areBusinessCodes(res)) {
        showErrorDialog({
          title: 'Attention',
          description: '',
          apiError: {
            ...res.error.status
          }
        })
      }

      throw res
    })
  )
}

const isGoogleAuthUrl = (url: string): boolean => {
  return new RegExp('/login/google').test(url)
}

const isExpiredSession = (res: HttpErrorResponse): boolean => {
  return sessionIsExpired.apiError?.code === res.error.status.code
    || missingAuthorizationHeader.apiError?.code === res.error.status.code
}

const isLoggedInTwice = (res: HttpErrorResponse): boolean => {
  return loggedInTwice.apiError?.code === res.error.status.code
}

const doesNotExistUserOrRoleIsChanged = (res: HttpErrorResponse): boolean => {
  return userDoesNotExistOrRoleIsChanged.apiError?.code === res.error.status.code
}

const areBusinessCodes = (res: HttpErrorResponse): boolean => {
  return notFoundBiller.apiError?.code === res.error.status.code
    || duplicatedData.apiError?.code === res.error.status.code
    || dataMustBeNumericFormat.apiError?.code === res.error.status.code
    || dateMustBeGreaterThanOrEqualCurrentDate.apiError?.code === res.error.status.code
    || fileTypeMustBePng.apiError?.code === res.error.status.code
    || endDateMustBeGreaterThanOrEqualStartDate.apiError?.code === res.error.status.code
}
