import { ApplicationRef, enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { mockAccesses } from './app/constants/mock-accesses';
import { storageKey } from './app/constants/storage-key';
import { AuthRes } from './app/models/auth-res';
import { LocalStorageService } from './app/services/local-storage.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode()
  console.log = () => { }
}

bootstrapApplication(AppComponent, appConfig)
  .then((ref: ApplicationRef) => {
    if (environment.name === 'MOCK') {
      const mockAccess = mockAccesses.dev
      console.log(`enable MOCK enviroment ${mockAccess.name}`)
      const auth: AuthRes = {
        authorization: 'mock',
        userRefId: 'mock',
        name: 'Mock',
        email: 'mock@arise.co.th',
        access: mockAccess.value,
      }
      ref.injector.get(LocalStorageService).set(storageKey.auth, auth)
    }
  })
  .catch((err) => console.error(err));
