import { Injectable, inject } from "@angular/core";
import { authAccesses } from "../constants/auth-accesse";
import { storageKey } from "../constants/storage-key";
import { AuthAccess } from "../models/auth-access";
import { AuthRes } from "../models/auth-res";
import { AuthAccessStore } from "./auth-access.store";
import { RefreshStorageService } from "./refresh-storage.service";

@Injectable({ providedIn: 'root' })
export class AuthAccessService {
  private authAccessStore = inject(AuthAccessStore)
  private refreshStorageServices = inject(RefreshStorageService)

  get(): AuthAccess[] {
    // check after reload page
    if (this.authAccessStore.get().length === 0) {
      const authRes: AuthRes | null = this.refreshStorageServices.get<AuthRes>(storageKey.auth)
      this.authAccessStore.set(authRes ? authRes.access : [])
    }

    const accesses = this.authAccessStore.get()
    const list: AuthAccess[] = []
    accesses.forEach(no => {
      const a = authAccesses.find(a => a.no === no)
      if (a) list.push(a)
    })

    return list
  }
}