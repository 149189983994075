/*
title and description properties mean front display message to end users.
but apiError property present code and description from below link (from backend)
https://ktbinnovation.atlassian.net/wiki/spaces/PMT/pages/3302031776/Admin+Portal+Custom+Error+and+Description+Mapping+v1.1.0

use DisplyInfoService for select description front display message frist, if empty use apiError description
*/

import { DisplayInfo } from "../models/display-info"

export const loggedInTwice: DisplayInfo = { description: '', apiError: { code: 'PPAP30005', description: 'This user is currently log in on another browser/device.' } }
export const sessionIsExpired: DisplayInfo = { title: 'Session Ended', description: 'The session has been closes due to inactivity for 15 mins.', apiError: { code: 'PPAP30004', description: 'Session does not exist or expired.' } }
export const missingAuthorizationHeader: DisplayInfo = { description: '', apiError: { code: 'PPAP80003', description: 'Authorization header is missing, incorrect or invalid authorization token.' } }
export const dataNotFound: DisplayInfo = { description: '', apiError: { code: 'PPAP80007', description: 'Record not found.' } }
export const userDoesNotExistOrRoleIsChanged: DisplayInfo = { description: '', apiError: { code: 'PPAP30010', description: 'This user information has been changed or deleted.' } }

/*
business codes by Boss.
*/
export const notFoundBiller: DisplayInfo = { description: '', apiError: { code: 'PPAP30011', description: 'Not found biller in biller profile.' } }
export const duplicatedData: DisplayInfo = { description: '', apiError: { code: 'PPAP30012', description: 'Duplicate data.' } }
export const dataMustBeNumericFormat: DisplayInfo = { description: '', apiError: { code: 'PPAP30013', description: 'Please input the data in numeric format.' } }
export const dateMustBeGreaterThanOrEqualCurrentDate: DisplayInfo = { description: '', apiError: { code: 'PPAP30014', description: 'Date must be greater than or equal current date.' } }
export const fileTypeMustBePng: DisplayInfo = { description: '', apiError: { code: 'PPAP30015', description: 'File type must be .PNG' } }
export const endDateMustBeGreaterThanOrEqualStartDate: DisplayInfo = { description: '', apiError: { code: 'PPAP30016', description: 'End date must be greater than or equal start date.' } }

/*
make it for only some front logic. 
*/
export const notifyLoggedInTwice: DisplayInfo = { title: `You've been signed out.`, description: `You've been signed out due to multiple sign in detected.`, apiError: { code: 'PPAP30005', description: '' } }